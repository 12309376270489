import React, {useEffect, useState} from "react";
import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Alert from "../../../../../helpers/Alert";
import clientesApi from "../../../../../services/clientesApi";
import processosApi from "../../../../../services/processosApi";
import ViaCepService from "../../../../../services/viaCepApi";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";
import Button from "../../../../../components/button/Button";

export interface IDadosPessoaisAusVisitante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosPessoaisAusEstudante({processo, setProcesso, setTelaNumero, telanumero}: IDadosPessoaisAusVisitante) {
    const [state, setState] = useState({
        id: '',
        nome: '',
        sobrenome: '',
        possui_nomes_anteriores: 0,
        nomes_anteriores: '',
        sexo: 'm',
        cpf: '',
        rg: '',
        orgao_emissor: '',
        nascimento: '',
        possui_outra_nascimento: 0,
        outra_nascimento: '',
        cidade_natal: '',
        pais_natal: '',

        estado_civil: 'solteiro',
        nome_completo_conjuge: '',

        possui_outra_nacionalidade: 0,
        outras_cidadanias: '',
        nacionalidade: '',

        endereco: '',
        cidade: '',
        cep: '',
        pais_residencia: '',
        telefone: '',
        telefone_secundario: '',
        telefone_trabalho: '',
        email: '',
    });

    const stadoCivilOptions = [
        { value: 'solteiro', label: 'Solteiro(a)' },
        { value: 'casado', label: 'Casado(a)' },
        { value: 'uniao estavel', label: 'União Estável' },
        { value: 'separado', label: 'Separado(a)' },
        { value: 'desquitado', label: 'Desquitado(a)' },
        { value: 'viuvo', label: 'Viúvo(a)' },
        { value: 'divorciado', label: 'Divorciado' },
    ];
    const getData = async () => {
        const {data, isError} = await clientesApi.one(processo.cliente_id);
        if (!isError){
            if (Object.values(data.data).length){
                const response = data.data;
                setState(
                    {
                        id: response.id,
                        nome: response.nome,
                        sobrenome: response.sobrenome,
                        possui_nomes_anteriores: response.possui_nomes_anteriores ? response.possui_nomes_anteriores : 0,
                        nomes_anteriores: response.nomes_anteriores,
                        sexo: response.nomes_anteriores ? response.nomes_anteriores : 'm',
                        cpf: response.cpf,
                        rg: response.rg,
                        orgao_emissor: response.orgao_emissor,
                        nascimento: response.nascimento,
                        possui_outra_nascimento: response.possui_outra_nascimento ? response.possui_outra_nascimento : 0,
                        outra_nascimento: response.outra_nascimento,
                        cidade_natal: response.cidade_natal,
                        pais_natal: response.pais_natal,

                        estado_civil: response.estado_civil ? response.estado_civil : 'solteiro',
                        nome_completo_conjuge: response.nome_completo_conjuge,

                        possui_outra_nacionalidade: response.possui_outra_nacionalidade ? response.possui_outra_nacionalidade : 0,
                        outras_cidadanias: response.outras_cidadanias,
                        nacionalidade: response.nacionalidade,

                        endereco: response.endereco,
                        cidade: response.cidade,
                        cep: response.cep,
                        pais_residencia: response.pais_residencia,
                        telefone: response.telefone,
                        telefone_secundario: response.telefone_secundario,
                        telefone_trabalho: response.telefone_trabalho,
                        email: response.usuario.email,
                    }
                )
            }
        }
    }
    useEffect(() => {
        getData()
    }, []);
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    async function onSubmit() {
        Alert.await("Salvando dados");
        const data = await clientesApi.update(state.id, state);
        if (data.isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        let per = processo.percet_conclusao;
        if (processo.percet_conclusao === 0){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            processosApi.update(processo.id, {percet_conclusao: percent})
            per = percent;
        }
        const cliente = data.data.data;
        //cliente.estado_civil = state.estado_civil;
        setProcesso({...processo, 'cliente':cliente, percet_conclusao:per })
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
        //setTelaNumero(6);
    }
    const cepSrv = new ViaCepService();
    const handleChangeCep = (value: any) => {
        cepSrv.consultaCep(state.cep).then((cepResponse: any) =>{
            if (!cepResponse.data.erro){
                setState({
                    ...state,
                    endereco: cepResponse.data.logradouro,
                    cidade: cepResponse.data.localidade+' '+cepResponse.data.uf,
                    pais_residencia: 'Brasil',

                });
            }
        });
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados Pessoais'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form   onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nome'}
                            placeholder={'Nome'}
                            large={true}
                            required={true}
                            name={'nome'}
                            setValue={e => handleChange(e,'nome')}
                            value={state.nome}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Sobrenome'}
                            placeholder={'Sobrenome'}
                            large={true}
                            required={true}
                            name={'sobrenome'}
                            setValue={e => handleChange(e,'sobrenome')}
                            value={state.sobrenome}
                        />
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Você já possuiu outro nome?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'possui_nomes_anteriores')}
                                        value={1}
                                        id="checked-possui_nomes_anteriores-s"
                                        type="radio"
                                        checked={state.possui_nomes_anteriores === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_nomes_anteriores-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.possui_nomes_anteriores === 0 }
                                        onChange={e => handleChange(0,'possui_nomes_anteriores')}
                                        id="checked-possui_nomes_anteriores-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_nomes_anteriores-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Nomes anteriores'}
                            placeholder={'Nomes anteriores'}
                            large={true}
                            name={'nomes_anteriores'}
                            setValue={e => handleChange(e,'nomes_anteriores')}
                            value={state.nomes_anteriores}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Sexo *
                            </label>
                            <div className={'flex mt-[1.5rem] gap-4'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(e.target.value,'sexo')}
                                        value="m"
                                        id="checked-sexo-m"
                                        type="radio"
                                        checked={state.sexo === "m" || state.sexo === ""}
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-sexo-m"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Masculino
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.sexo === "f"}
                                        onChange={e => handleChange(e.target.value,'sexo')}
                                        id="checked-sexo-f"
                                        type="radio"
                                        value="f"
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-sexo-f"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Feminino
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'CPF'}
                            placeholder={'CPF'}

                            large={true}
                            required={true}
                            name={'cpf'}
                            setValue={e => handleChange(e,'cpf')}
                            value={state.cpf}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'RG'}
                            placeholder={'RG'}
                            large={true}
                            name={'rg'}
                            setValue={e => handleChange(e,'rg')}
                            value={state.rg}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Orgão emissor'}
                            placeholder={'Orgão emissor'}
                            large={true}
                            name={'orgao_emissor'}
                            setValue={e => handleChange(e,'orgao_emissor')}
                            value={state.orgao_emissor}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Data de nascimento '}
                            type={'date'}
                            placeholder={'Data de nascimento'}
                            large={true}
                            name={'nascimento'}
                            setValue={e => handleChange(e,'nascimento')}
                            value={state.nascimento}
                        />
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Possui outra data de nascimento?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={() => handleChange(1,'possui_outra_nascimento')}
                                        value={1}
                                        id="checked-possui_outra_nascimento-s"
                                        type="radio"
                                        checked={state.possui_outra_nascimento === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_outra_nascimento-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.possui_outra_nascimento === 0 }
                                        onChange={e => handleChange(0,'possui_outra_nascimento')}
                                        id="checked-possui_outra_nascimento-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_outra_nascimento-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>

                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Caso afirmativo, qual?'}
                            placeholder={'Caso afirmativo, qual?'}
                            large={true}
                            name={'outra_nascimento'}
                            setValue={e => handleChange(e,'outra_nascimento')}
                            value={state.outra_nascimento}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Cidade e estado de nascimento'}
                            placeholder={'Cidade e estado de nascimento'}
                            large={true}
                            name={'cidade_natal'}
                            setValue={e => handleChange(e,'cidade_natal')}
                            value={state.cidade_natal}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'País de nascimento'}
                            placeholder={'País de nascimento'}
                            large={true}
                            required={true}
                            name={'pais_natal'}
                            setValue={e => handleChange(e,'pais_natal')}
                            value={state.pais_natal}
                        />
                        <Input
                            large={true}
                            className={'md:col-span-1'}
                            label={'CEP'}
                            name={'cep'}

                            setValue={e => handleChange(e, 'cep')}
                            onBlur={(e: any) => handleChangeCep(e)}
                            value={state.cep}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Endereço'}
                            name={'endereco'}
                            setValue={e => handleChange(e,'endereco')}
                            value={state.endereco}
                        />
                        <Input
                            large={true}
                            className={'md:col-span-1'}
                            label={'País que reside'}
                            name={'pais_residencia'}
                            setValue={e => handleChange(e,'pais_residencia')}
                            value={state.pais_residencia}
                        />
                    </div>
                    <div className={'grid md:grid-cols-5 gap-4 mt-5'}>
                        <Input
                            mask={'cellphone'}
                            className={'col-span-1'}
                            label={'Telefone Primario'}
                            placeholder={'Telefone Primario'}
                            large={true}
                            name={'telefone'}
                            setValue={e => handleChange(e,'telefone')}
                            value={state.telefone}
                        />
                        <Input
                            mask={'cellphone'}
                            className={'col-span-1'}
                            label={'Telefone secundário'}
                            placeholder={'Telefone secundário'}
                            large={true}
                            name={'telefone_secundario'}
                            setValue={e => handleChange(e,'telefone_secundario')}
                            value={state.telefone_secundario}
                        />
                        <Input
                            mask={'cellphone'}
                            className={'col-span-1'}
                            label={'Telefone do trabalho'}
                            placeholder={'Telefone trabalho'}
                            large={true}
                            name={'telefone_trabalho'}
                            setValue={e => handleChange(e,'telefone_trabalho')}
                            value={state.telefone_trabalho}
                        />
                        <Input
                            required={true}
                            large={true}
                            className={'md:col-span-2'}
                            label={'E-mail'}
                            name={'email'}
                            setValue={e => handleChange(e,'email')}
                            value={state.email}
                        />
                    </div>
                    <div className={'grid md:grid-cols-5 gap-4 mt-5'}>
                        <Input
                            large={true}
                            className={'md:col-span-1'}
                            label={'Nacionalidade'}
                            name={'nacionalidade'}
                            setValue={e => handleChange(e,'nacionalidade')}
                            value={state.nacionalidade}
                        />
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Você possui outra nacionalidade?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={() => handleChange(1,'possui_outra_nacionalidade')}
                                        value={1}
                                        id="checked-possui_outra_nacionalidade-s"
                                        type="radio"
                                        checked={state.possui_outra_nacionalidade === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_outra_nacionalidade-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.possui_outra_nacionalidade === 0 }
                                        onChange={e => handleChange(0,'possui_outra_nacionalidade')}
                                        id="checked-possui_outra_nacionalidade-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_outra_nacionalidade-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>

                        </div>

                        <Input
                            large={true}
                            className={'md:col-span-2'}
                            label={'Caso tenha respondido "Sim", especifique'}
                            name={'outras_cidadanias'}
                            setValue={e => handleChange(e,'outras_cidadanias')}
                            value={state.outras_cidadanias}
                        />
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Estado civil"}
                                selected={stadoCivilOptions.filter((opt: any) => opt.value === state.estado_civil)}
                                options={stadoCivilOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'estado_civil')}
                            />
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            large={true}
                            className={'md:col-span-1'}
                            label={'Caso esteja em uma relação estável. Qual a data de inicio da relação?'}
                            name={'nome_completo_conjuge'}
                            setValue={e => handleChange(e,'nome_completo_conjuge')}
                            value={state.nome_completo_conjuge}
                        />
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-end p-5'}>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>

                </div>
            </Form>
        </>
    )
}