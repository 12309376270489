import React, {useEffect, useState} from "react";
import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Alert from "../../../../../helpers/Alert";
import clientesApi from "../../../../../services/clientesApi";
import processosApi from "../../../../../services/processosApi";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";
import Button from "../../../../../components/button/Button";
import {Spinner} from "@react-pdf-viewer/core";

export interface IDadosPessoaisCa{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosPessoaisCa({processo, setProcesso, setTelaNumero, telanumero}: IDadosPessoaisCa) {
    const [loading, setLoading] = useState(true);
    const [dadosPessoais, setDadosPessoais] = useState({
        id: processo.cliente.id,
        nome_responsavel: processo.cliente.nome_responsavel,
        telefone_responsavel: processo.cliente.telefone_responsavel,
        email_responsavel: processo.cliente.email_responsavel,
        nome: processo.cliente.nome,
        sobrenome: processo.cliente.sobrenome,
        nomes_anteriores: processo.cliente.nomes_anteriores,
        nacionalidade: processo.cliente.nacionalidade,
        nascimento: processo.cliente.nascimento,
        sexo: processo.cliente.sexo ? processo.cliente.sexo : 'm',
        estado_civil: processo.cliente.estado_civil,
        cidade_natal: processo.cliente.cidade_natal,
        outras_cidadanias: processo.cliente.outras_cidadanias,
        brasileiro: processo.cliente.brasileiro ? processo.cliente.brasileiro : 0,
        cpf: processo.cliente.cpf,
        obs_fora_pais: processo.cliente.obs_fora_pais,
    });
    const stadoCivilOptions = [
        { value: 'solteiro', label: 'Solteiro(a)' },
        { value: 'casado', label: 'Casado(a)' },
        { value: 'uniao estavel', label: 'União Estável' },
        { value: 'divorciado', label: 'Divorciado' },
        { value: 'viuvo', label: 'Viúvo(a)' },
        { value: 'separado', label: 'Separado(a)' },
        { value: 'desquitado', label: 'Desquitado(a)' },
    ];

    const getData = async () => {
        const  {data, isError} = await clientesApi.one(processo.cliente.id);
        setDadosPessoais({
            id: data.data.id,
            nome_responsavel: data.data.nome_responsavel,
            telefone_responsavel: data.data.telefone_responsavel,
            email_responsavel: data.data.email_responsavel,
            nome: data.data.nome,
            sobrenome: data.data.sobrenome,
            nomes_anteriores: data.data.nomes_anteriores,
            nacionalidade: data.data.nacionalidade,
            nascimento: data.data.nascimento,
            sexo: data.data.sexo ? data.data.sexo : 'm',
            estado_civil: data.data.estado_civil,
            cidade_natal: data.data.cidade_natal,
            outras_cidadanias: data.data.outras_cidadanias,
            brasileiro: data.data.brasileiro ? data.data.brasileiro : 0,
            cpf: data.data.cpf,
            obs_fora_pais: data.data.obs_fora_pais,
        })
        setLoading(false)
    }

    useEffect(() => { getData() }, [])

    async function onSubmit() {
        Alert.await("Salvando dados");
        const data = await clientesApi.update(dadosPessoais.id, dadosPessoais);
        if (data.isError){
            if (data.data.message){
                return Alert.error(data.data.message? data.data.message : data.data);
            }
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        let per = processo.percet_conclusao;
        if (processo.percet_conclusao === 0){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            processosApi.update(processo.id, {percet_conclusao: percent})
            per = percent;
        }
        const cliente = data.data.data;
        cliente.estado_civil = dadosPessoais.estado_civil;
        setProcesso({...processo, 'cliente':cliente, percet_conclusao:per })
        Alert.close();
        window.scrollTo(0, 0);
        let numero = telanumero + 1;
        if (dadosPessoais.estado_civil === 'solteiro' || dadosPessoais.estado_civil === 'divorciado') {
            numero = telanumero + 3;
        }
        setTelaNumero(numero);
        //setTelaNumero(16);
    }
    const handleChange = (value: any, input: string) => {
        setDadosPessoais({...dadosPessoais, [input]: value});
    };
    if (loading) return (<div className={'text-[16px] flex justify-center'}><Spinner  /></div>);
    return(
        <>
            <HeaderForm
                titulo={'Dados Pessoais'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>

                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nome '}
                            placeholder={'Nome'}
                            large={true}
                            name={'nome'}
                            setValue={e => handleChange(e,'nome')}
                            value={dadosPessoais.nome}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Sobrenome '}
                            placeholder={'Sobrenome'}
                            large={true}
                            name={'sobrenome'}
                            setValue={e => handleChange(e,'sobrenome')}
                            value={dadosPessoais.sobrenome}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Nomes anteriores'}
                            placeholder={'Nomes anteriores'}
                            large={true}
                            name={'nomes_anteriores'}
                            setValue={e => handleChange(e,'nomes_anteriores')}
                            value={dadosPessoais.nomes_anteriores}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'CPF '}
                            placeholder={'CPF'}

                            large={true}
                            name={'cpf'}
                            setValue={e => handleChange(e,'cpf')}
                            value={dadosPessoais.cpf}
                            required
                        />
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Sexo *
                            </label>
                            <div className={'flex mt-[1.5rem] gap-4'}>
                                <div className={'flex'}>
                                    <input

                                        onChange={e => handleChange(e.target.value,'sexo')}
                                        value="m"
                                        id="checked-sexo-m"
                                        type="radio"
                                        checked={dadosPessoais.sexo === "m" || dadosPessoais.sexo === ""}
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-sexo-m"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Masculino
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={dadosPessoais.sexo === "f"}
                                        onChange={e => handleChange(e.target.value,'sexo')}
                                        id="checked-sexo-f"
                                        type="radio"
                                        value="f"
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-sexo-f"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Feminino
                                    </label>
                                </div>


                            </div>

                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Data de nascimento '}
                            type={'date'}
                            placeholder={'Data de nascimento'}
                            large={true}
                            name={'nascimento'}
                            setValue={e => handleChange(e,'nascimento')}
                            value={dadosPessoais.nascimento}
                            required
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Cidade e estado de Nascimento '}
                            placeholder={'Cidade e estado de Nascimento'}
                            large={true}
                            name={'cidade_natal'}
                            setValue={e => handleChange(e,'cidade_natal')}
                            value={dadosPessoais.cidade_natal}
                            required
                        />
                        <div className={'col-span-1 flex flex-col'}>
                            <label
                                className="text-[16px] font-medium text-gray-900 dark:text-gray-300"
                            >
                                Brasileiro
                            </label>
                            <div className={'flex mt-[1.5rem] gap-4'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'brasileiro')}
                                        value={1}
                                        id="checked-brasileiro-1"
                                        type="radio"
                                        checked={dadosPessoais.brasileiro === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-brasileiro-1"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={dadosPessoais.brasileiro === 0 }
                                        onChange={e => handleChange(0,'brasileiro')}
                                        id="checked-brasileiro"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-brasileiro"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Outras cidadanias'}
                            placeholder={'Outras cidadanias'}
                            large={true}
                            name={'outras_cidadanias'}
                            setValue={e => handleChange(e,'outras_cidadanias')}
                            value={dadosPessoais.outras_cidadanias}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Estado civil"}
                                selected={stadoCivilOptions.filter((opt: any) => opt.value === dadosPessoais.estado_civil)}
                                options={stadoCivilOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'estado_civil')}
                                required
                            />
                        </div>
                        <div className={'col-span-2 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Você esteve por mais de seis meses longe do seu país de residência nos últimos cinco anos?
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'obs_fora_pais'}
                                onChange={e => handleChange(e.target.value,'obs_fora_pais')}
                                value={dadosPessoais.obs_fora_pais ? dadosPessoais.obs_fora_pais : ""}
                            />
                            <div className={'text-sm text-gray-400'}>
                                Se sim, descreva: País / Situação Migratória / Data de Entrada / Data de Saída.
                            </div>

                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-end p-5'}>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>

                </div>
            </Form>
        </>
    )
}